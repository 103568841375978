import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
export default function Contactinfo({person, logo,  name }) {
    return (
        <ContactinfoWrap className="container d-flex p-0">
            
           <div className="imge">
               <div className="my-4">
              <Link to="/"><img src={logo} alt="logo" width="250px"/></Link> 
               </div>
                
               <h3 className="text-center text-uppercase">{name}</h3>


           </div>
    <div className="container contact mt-5">
           <div className="row">
          
             {
                 person.map((item, i)=>{

                  return(<React.Fragment  key={i}>
                  
                  <div className="col-2 my-2"  >

               <div className="mx-2 ">{item.icon}</div>
                  </div>

               <div className="col-10 my-2" >
           {item.title2 && <p className="telphone"><a style={{color:"grey"}} href={`tel: ${item.ppath1}`}>{item.title}</a>&nbsp; | &nbsp; <a style={{color:"grey"}} href={`tel: ${item.ppath2}`}>{item.title2}</a>  </p> 
}

{ !item.title2 &&  <p><a style={{color:"grey"}} target="_blank" href={ item.title.includes("@") ? `mailto:${item.ppath1}` : `${item.ppath1}`}>{item.title}</a></p> }

 </div>
                  
                  </React.Fragment>)
                   
         
                    
                 })
             }
  </div>  
           </div>

        </ContactinfoWrap>
    )
}

const ContactinfoWrap = styled.div`

color:grey;
background-color:#000000;
flex-direction:column;
align-items:center;
justify-content:center;

.contact{

 p{
font-size:1.1rem;
letter-spacing:0.01rem;
line-height:1.5;
}

.telphone{

    font-size:1.1rem;
}
svg{

    color:orange;
    font-size:1.5rem;
}  
}

`