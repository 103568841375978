import React from 'react'
import styled from 'styled-components'
import Hgallery from '../NewCOmponents/gridgallery'
import HomeImg from '../../images/Home.jpg'
import {useStaticQuery,  graphql} from 'gatsby'
import Image from 'gatsby-image'
export default function ImageSec({content}) {


    const hgaldata = useStaticQuery(graphql`

    query  {
          allFile(filter: {
            extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
            sourceInstanceName: {eq: "Hgallery"}}, sort: {fields: size}) 
          {
            edges {
              node {
                childImageSharp {
                  fluid {
                  originalName
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
    
    
    `)
    
    
    const ContGall = hgaldata.allFile.edges

    return (
        <ImagWrap className="pt-4 ">
            <div>
          <img style={{borderTop:"4px solid orange"}} className="imgI" src={HomeImg} alt="s" width="100%"/>  
       <br/>
    <h1 className="text-center">About us</h1>
    <p className="px-2" >{content }</p>
       </div>
<br/>
<br/>

<div className="row">

{

ContGall.map((item, i)=>{

return <div className="col-sm-4 col-md-3"  key={i}>
<Image fluid={item.node.childImageSharp.fluid}  alt="hgal" style={{width:"100%"}}/>
</div>

})

}

</div>




{/* 
       <div className="mt-4 ">
           <h2 className="p-3 m-0 text-center bg-light"><a style={{color:"grey"}} target="new_target" href="/house">RESIDENTIAL PORTFOLIO</a></h2>
           <img src="https://www.designseries.co.in/static/1-7bd57c15a8febf0c0e3db6942c93cc54.jpg" alt="sds" width="100%"/>
           <h2 className="p-3 text-white text-center m-0" style={{backgroundColor:"#ff770c"}}>
           <a style={{color:"#f5f5f5"}} target="new_target" href="/office">OFFICE  PORTFOLIO</a></h2>
           <img src="https://www.designseries.co.in/static/1-7bd57c15a8febf0c0e3db6942c93cc54.jpg" alt="sds" width="100%"/>
       </div> */}
        </ImagWrap>
    )
}

const ImagWrap = styled.div`
background-color:#000000;

h1{
 color:orange;  
 text-transform:uppercase;


}


    a{

text-decoration:none;

}


 
p{

    color:grey;
    text-align:center;
    font-size:1.2rem;
    letter-spacing:0.02rem;
    line-height:1.6;

}

`
