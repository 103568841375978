import React from 'react'
import styled from 'styled-components'

export default function Iconlist({siconlist}) {



    return (
        <IconlistWrap color={siconlist} className="container d-flex align-items-center justify-content-between">
            {

siconlist.map((item, i)=>{

    return <div className=" social py-3" key={i}>
   <a href={item.path} target="_blank" style={{color:item.color}} >{item.sicon}</a>
        
        </div>
})
            }

            
        </IconlistWrap>
    )
}

const IconlistWrap = styled.div`

background-color:#000000;

.social{


  a{

svg{

font-size:2rem;




}

}

    }
    
    
   
`